import type { BoxProps } from '@mui/material/Box';

import { useState } from 'react';
import { m } from 'framer-motion';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Accordion, { accordionClasses } from '@mui/material/Accordion';
import AccordionDetails, { accordionDetailsClasses } from '@mui/material/AccordionDetails';
import AccordionSummary, { accordionSummaryClasses } from '@mui/material/AccordionSummary';

import { varAlpha } from 'src/theme/styles';

import { Iconify } from 'src/components/iconify';
import { varFade, MotionViewport } from 'src/components/animate';

import { SectionTitle } from './components/section-title';
import { FloatLine, FloatPlusIcon, FloatTriangleDownIcon } from './components/svg-elements';

// ----------------------------------------------------------------------

// const FAQs = [
//   {
//     question: 'How can I get the update?',
//     answer: (
//       <Typography>
//         You will get 12 months of free
//         <Link
//           href="https://support.mui.com/hc/en-us/articles/360008775240-How-do-I-get-access-to-an-item-I-purchased"
//           target="_blank"
//           rel="noopener"
//           sx={{ mx: 0.5 }}
//         >
//           updates
//         </Link>
//         with the purchase. Please renew your license to get updates after that.
//       </Typography>
//     ),
//   },
//   {
//     question: 'Which license is right for you?',
//     answer: (
//       <Box component="ul" sx={{ pl: 3, listStyleType: 'disc' }}>
//         <li> All licenses do not apply to open source.</li>
//         <li> One licenses / one end product (3 licenses / 3 products...).</li>
//         <li>
//           <strong>Standard / Plus</strong> license used in free products (Internal management...).
//         </li>
//         <li>
//           <strong>Extended</strong> license used in charge products, collect fees from users
//           (SAAS...).
//         </li>
//         <li>
//           Learn more about the
//           <Link
//             href="https://docs.minimals.cc/package/"
//             target="_blank"
//             rel="noopener"
//             sx={{ mx: 0.5 }}
//           >
//             package & license
//           </Link>
//         </li>
//       </Box>
//     ),
//   },
//   {
//     question: 'How long is my license valid for?',
//     answer: (
//       <Box component="ul" sx={{ pl: 3, listStyleType: 'disc' }}>
//         <li> The license is lifetime.</li>
//         <li> You get 12 months of free updates.</li>
//       </Box>
//     ),
//   },
//   {
//     question: 'Which platforms will the template support?',
//     answer: (
//       <Typography>
//         {`The components in MUI are designed to work in the latest, stable releases of all major browsers, including Chrome, Firefox, Safari, and Edge. We don't support Internet Explorer 11. `}
//         Learn more about the
//         <Link
//           href="https://mui.com/material-ui/getting-started/supported-platforms/"
//           target="_blank"
//           rel="noopener"
//           sx={{ mx: 0.5 }}
//         >
//           supported platforms
//         </Link>
//       </Typography>
//     ),
//   },
//   {
//     question: 'For what kind of projects is the Standard license intended?',
//     answer: (
//       <Typography>
//         The Standard license is designed for internal applications in which staff will access the
//         application. An example could be the back-office dashboard of a public-facing e-commerce
//         website in which staff would sign in and manage inventory, customers, etc.
//       </Typography>
//     ),
//   },
//   {
//     question: 'Do you have a free demo to review the code before purchasing?',
//     answer: (
//       <Typography>
//         Yes, you can check out our
//         <Link
//           href="https://mui.com/store/items/minimal-dashboard-free/"
//           target="_blank"
//           rel="noopener"
//           sx={{ mx: 0.5 }}
//         >
//           open source
//         </Link>
//         dashboard template which should give you an overview of the code quality and folder
//         structure. Keep in mind that some aspects may differ from this Paid version.
//       </Typography>
//     ),
//   },
// ];
const FAQs = [
  {
    question: 'What is SPHERE Iraq?',
    answer: (
      <Typography>
        SPHERE Iraq is an all-in-one management software tailored for companies in the distribution and retail sectors, focusing on warehouse management and efficiency.
      </Typography>
    ),
  },
  {
    question: 'Who is SPHERE Iraq designed for?',
    answer: (
      <Box component="ul" sx={{ pl: 3, listStyleType: 'disc' }}>
        <li>
          <strong>Distribution Companies:</strong> Perfect for managing inventory and supply chains.
        </li>
        <li>
          <strong>Warehouse Managers:</strong> Streamlines warehouse operations.
        </li>
        <li>
          <strong>Retailers:</strong> Enhances efficiency for product distribution.
        </li>
        <li>
          <strong>Businesses of All Sizes:</strong> lFlexible solutions for small to large enterprises.
        </li>
      </Box>
    ),
  },
  {
    question: 'Can SPHERE Iraq be customized for my business needs?',
    answer: (
      <Box component="ul" sx={{ pl: 3, listStyleType: 'disc' }}>
        <strong>Yes,</strong> SPHERE Iraq offers full customization options to fit your business’s specific workflows and requirements.
      </Box>
    ),
  },
  {
    question: 'Does SPHERE Iraq support multiple languages and currencies?',
    answer: (
      <Typography>
        Absolutely! SPHERE Iraq supports multi-language and multi-currency features, making it suitable for businesses with global operations.
      </Typography>
    ),
  },
  {
    question: 'What solutions does SPHERE Iraq offer?',
    answer: (
      <Typography>
        SPHERE Iraq provides solutions for warehouse management, inventory tracking, distribution efficiency, and tailored reporting for companies in the distribution sector.
      </Typography>
    ),
  },
  {
    question: 'How can I get started with SPHERE Iraq?',
    answer: (
      <Typography>

        Simply contact us through our
        <Link
          href="https://sphereiraq.com"
          target="_blank"
          rel="noopener"
          sx={{ mx: 0.5 }}
        >
          website
        </Link>
        or
        <Link
          href="mailto:info@sphereiraq.com?subject=[Info] from Customer"
          target="_blank"
          rel="noopener"
          sx={{ mx: 0.5 }}
        >
          email
        </Link>
        , and our team will provide a demo and assist you in implementing SPHERE Iraq for your business.
      </Typography>
    ),
  },
];

// ----------------------------------------------------------------------

export function HomeFAQs({ sx, ...other }: BoxProps) {
  const [expanded, setExpanded] = useState<string | false>(FAQs[0].question);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderDescription = (
    <SectionTitle
      caption="FAQs"
      title="We’ve got the"
      txtGradient="answers"
      sx={{ textAlign: 'center' }}
    />
  );

  const renderContent = (
    <Stack
      spacing={1}
      sx={{
        mt: 8,
        mx: 'auto',
        maxWidth: 720,
        mb: { xs: 5, md: 8 },
      }}
    >
      {FAQs.map((item, index) => (
        <Accordion
          key={item.question}
          component={m.div}
          variants={varFade({ distance: 24 }).inUp}
          expanded={expanded === item.question}
          onChange={handleChange(item.question)}
          sx={{
            borderRadius: 2,
            transition: (theme) =>
              theme.transitions.create(['background-color'], {
                duration: theme.transitions.duration.short,
              }),
            '&::before': { display: 'none' },
            '&:hover': {
              bgcolor: (theme) => varAlpha(theme.vars.palette.grey['500Channel'], 0.16),
            },
            '&:first-of-type, &:last-of-type': { borderRadius: 2 },
            [`&.${accordionClasses.expanded}`]: {
              m: 0,
              borderRadius: 2,
              boxShadow: 'none',
              bgcolor: (theme) => varAlpha(theme.vars.palette.grey['500Channel'], 0.08),
            },
            [`& .${accordionSummaryClasses.root}`]: {
              py: 3,
              px: 2.5,
              minHeight: 'auto',
              [`& .${accordionSummaryClasses.content}`]: {
                m: 0,
                [`&.${accordionSummaryClasses.expanded}`]: { m: 0 },
              },
            },
            [`& .${accordionDetailsClasses.root}`]: { px: 2.5, pt: 0, pb: 3 },
          }}
        >
          <AccordionSummary
            expandIcon={
              <Iconify
                width={20}
                icon={expanded === item.question ? 'mingcute:minimize-line' : 'mingcute:add-line'}
              />
            }
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
          >
            <Typography variant="h6"> {item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>{item.answer}</AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );

  const renderContact = (
    <Stack
      alignItems="center"
      sx={{
        px: 3,
        py: 8,
        textAlign: 'center',
        background: (theme) =>
          `linear-gradient(270deg, ${varAlpha(theme.vars.palette.grey['500Channel'], 0.08)}, ${varAlpha(theme.vars.palette.grey['500Channel'], 0)})`,
      }}
    >
      <m.div variants={varFade().in}>
        <Typography variant="h4">Still have questions?</Typography>
      </m.div>

      <m.div variants={varFade().in}>
        <Typography sx={{ mt: 2, mb: 3, color: 'text.secondary' }}>
          Please describe your case to receive the most accurate advice
        </Typography>
      </m.div>

      <m.div variants={varFade().in}>
        <Button
          color="inherit"
          variant="contained"
          href="mailto:info@sphereiraq.com?subject=[Info] from Customer"
          startIcon={<Iconify icon="fluent:mail-24-filled" />}
        >
          Contact us
        </Button>
      </m.div>
    </Stack>
  );

  return (
    <Box component="section" sx={{ ...sx }} {...other}>
      <MotionViewport sx={{ py: 10, position: 'relative' }}>
        <TopLines />

        <Container>
          {renderDescription}
          {renderContent}
        </Container>

        <Stack sx={{ position: 'relative' }}>
          <BottomLines />
          {renderContact}
        </Stack>
      </MotionViewport>
    </Box>
  );
}

// ----------------------------------------------------------------------

function TopLines() {
  return (
    <>
      <Stack
        spacing={8}
        alignItems="center"
        sx={{
          top: 64,
          left: 80,
          position: 'absolute',
          transform: 'translateX(-15px)',
        }}
      >
        <FloatTriangleDownIcon sx={{ position: 'static', opacity: 0.12 }} />
        <FloatTriangleDownIcon
          sx={{
            position: 'static',
            opacity: 0.24,
            width: 30,
            height: 15,
          }}
        />
      </Stack>
      <FloatLine vertical sx={{ top: 0, left: 80 }} />
    </>
  );
}

function BottomLines() {
  return (
    <>
      <FloatLine sx={{ top: 0, left: 0 }} />
      <FloatLine sx={{ bottom: 0, left: 0 }} />
      <FloatPlusIcon sx={{ top: -8, left: 72 }} />
      <FloatPlusIcon sx={{ bottom: -8, left: 72 }} />
    </>
  );
}
